import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import styles from './mapstyle.module.css';

export const MapContainer = ({ google, lat, lng }) => {
  return (
    <div className={styles.smallMap}>
      <Map
        className={styles.relativeMap}
        google={google}
        initialCenter={{ lat, lng }}
        center={{ lat, lng }}
        zoom={14}
      >
        <Marker position={{ lat, lng }} />
      </Map>
    </div>
  );
};

export const SmallMap = GoogleApiWrapper({
  apiKey: 'AIzaSyAa0xU_YvTYVgwHrlb__dxr5Orp1wBJM84'
})(MapContainer);

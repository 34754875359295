import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ArrowRightAlt } from '@material-ui/icons';
import Fade from 'react-reveal/Fade';

import useImage from '../shared/Image';

import styles from './Card.module.css';

const Card = cardData => {
  const { image, imgName, description } = cardData;

  const [renderedImaged, loaded] = useImage(image.url, image.alt, image.styling);
  return (
    <div>
      {loaded && (
        <Fade bottom distance="40px" duration={900}>
          {' '}
          <Link to={`tenants/${imgName}`} className={styles.link}>
            <div className={styles.card}>
              <div className={styles.imageWrapper}>{renderedImaged}</div>
              <h3> {description} </h3>
              <ArrowRightAlt className={styles.arrowIcon} />
            </div>
          </Link>
        </Fade>
      )}
      {!loaded && <div style={{ visibility: 'hidden' }}>{renderedImaged}</div>}
    </div>
  );
};

export default withRouter(Card);

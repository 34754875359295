import React from 'react';
import Fade from 'react-reveal/Fade';
import styling from './ForTenants.module.css';
import { useDocument } from '../../utils/firebase_utils';

const ForTenantsSubpage = ({ match }) => {
  // The Firebase backend uses keys in norwegian
  // which is supposed to be easy to read
  // for non-techincal users with snake case notation.
  // They are therefore not formatted the same,
  // so we use a map from the current path to get the correct field
  const pathToKeyMap = {
    'mistet-nokkel': 'glemt_nøkkel',
    husordensregler: 'husordensregler',
    internett: 'internett_kabel_tv',
    renhold: 'renhold',
    avfall: 'avfall'
  };

  const subpageName = match.params.id;
  if (!(subpageName in pathToKeyMap)) {
    return <div />;
  }

  const contentNameKey = pathToKeyMap[subpageName];

  const content = useDocument('textContent', 'forTentantsContent');
  const contentIsLoaded = Object.keys(content).length > 0;

  const title = contentIsLoaded ? content[contentNameKey].title : '';
  const description = contentIsLoaded ? content[contentNameKey].description : '';

  return (
    <div>
      <Fade duration={1000}>
        <div className={styling.header}>
          <h1 className={styling.contact}>{title}</h1>
        </div>
      </Fade>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default ForTenantsSubpage;

import React, { useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import styles from './Property.module.css';

const PropertyFilter = ({ updateArea }) => {
  const [area, setArea] = useState('Alle');

  const changeArea = e => {
    setArea(e.target.value);
    updateArea(e.target.value);
  };

  return (
    <div className={styles.propertyFilter}>
      <label htmlFor="area-select">Jeg ønsker å se eiendommer i området</label>
      <br />
      <TextField
        id="outlined-select-currency"
        select
        value={area}
        className={styles.areaInput}
        onChange={changeArea}
        margin="normal"
        variant="outlined"
      >
        <MenuItem value="Alle">Alle</MenuItem>
        <MenuItem value="Byåsen">Byåsen</MenuItem>
        <MenuItem value="Kalvskinnet">Kalvskinnet</MenuItem>
        <MenuItem value="Lerkendal">Lerkendal</MenuItem>
        <MenuItem value="Lofoten">Lofoten</MenuItem>
        <MenuItem value="Midtbyen">Midtbyen</MenuItem>
        <MenuItem value="Ranheim">Ranheim</MenuItem>
        <MenuItem value="Øya">Øya</MenuItem>
      </TextField>
    </div>
  );
};

export default PropertyFilter;

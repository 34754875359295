import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

import styling from './style.module.css';
import Section from '../../components/home/Section';
import useImage from '../../components/shared/Image';

import { useDocument } from '../../utils/firebase_utils';

const Home = () => {
  // Load text content
  const content = useDocument('textContent', 'frontpageContent');
  const contentIsLoaded = Object.keys(content).length > 0;

  const frontpageTitle = contentIsLoaded ? content.tittel_forside.title : '';
  const propertiesTitle = contentIsLoaded ? content.tekst_eiendommer_forside.title : '';
  const propertiesText = contentIsLoaded ? content.tekst_eiendommer_forside.text : '';
  const tenantsTitle = contentIsLoaded ? content.tekst_leietaker_forside.title : '';
  const tenantsText = contentIsLoaded ? content.tekst_leietaker_forside.text : '';
  const employeesTitle = contentIsLoaded ? content.tekst_ansatte_forside.title : '';
  const employeesText = contentIsLoaded ? content.tekst_ansatte_forside.text : '';

  // load images
  const images = useDocument('images', 'frontpageImages');
  const imagesLoaded = Object.keys(images).length > 0;

  const [mainImage, mainImageLoaded] = useImage(
    imagesLoaded ? images.hovedbilde_forside.photo : '',
    'Forsidebilde',
    styling.img
  );

  const [propertiesImage, propertiesImageLoaded] = useImage(
    imagesLoaded ? images.bilde_eiendommer_forside.photo : '',
    'Flotte eiendommer',
    styling.img2
  );
  const [forTenantsImage, forTenantsImageLoaded] = useImage(
    imagesLoaded ? images.bilde_internett_forside.photo : '',
    'Internett',
    styling.img3
  );

  const [contactImage, contactImageLoaded] = useImage(
    imagesLoaded ? images.bilde_ansatte_forside.photo : '',
    'Bilde kontakt oss',
    styling.img2
  );

  return (
    <div id="container" className={styling.sections}>
      <Section loaded={mainImageLoaded} header>
        <div className={styling.fadeIn}>
          <div className={styling.headerText}>
            <div />
            <h1 className={styling.headerTextBackground}>{frontpageTitle}</h1>
          </div>
          {mainImage}
        </div>
      </Section>

      <div className={styling.subSections}>
        <Section loaded={propertiesImageLoaded && mainImageLoaded}>
          <div className={styling.fadeInLeft}>
            {propertiesImage}

            <div className={styling.sectionText}>
              <h1>{propertiesTitle}</h1>
              <h2>{propertiesText}</h2>

              <NavLink to="/properties">
                <Icon>arrow_right_alt</Icon>Se våre eiendommer
              </NavLink>
            </div>
          </div>
        </Section>
        <Section loaded={forTenantsImageLoaded && propertiesImageLoaded && mainImageLoaded}>
          <div className={styling.fadeInRight}>
            <div className={styling.sectionText}>
              <h1>{tenantsTitle}</h1>
              <h2>{tenantsText}</h2>
              <NavLink to="/tenants">
                <Icon>arrow_right_alt</Icon>For leietakere
              </NavLink>
            </div>
            {forTenantsImage}
          </div>
        </Section>
        <Section
          loaded={
            contactImageLoaded && forTenantsImageLoaded && propertiesImageLoaded && mainImageLoaded
          }
        >
          <div className={styling.fadeInLeft}>
            {contactImage}
            <div className={styling.sectionText}>
              <h1>{employeesTitle}</h1>
              <h2>{employeesText}</h2>
              <NavLink to="/contact">
                <Icon>arrow_right_alt</Icon>Kontakt oss
              </NavLink>
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Footer from './components/shared/Footer';
import NavBar from './components/shared/NavBar';
import AboutUs from './pages/about-us/AboutUs';
import AvailableHousing from './pages/available-housing/AvailableHousing';
import Contact from './pages/contact/Contact';
import Employees from './pages/employees/Employees';
import ForTenants from './pages/for-tenants/ForTenants';
import ForTenantsSubpage from './pages/for-tenants/ForTenantsSubpage';
import Home from './pages/home/Home';
import PropertyListScreen from './pages/properties/PropertyListScreen';
import PropertyScreen from './pages/properties/PropertyScreen';
import ReportError from './pages/report-error/ReportError';
import styles from './styles/layout.module.css';

function initializeReactGA() {
  ReactGA.initialize('UA-138510317-1');
  ReactGA.pageview('/');
}

initializeReactGA();

const withContainer = Page => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...{}
    });
    ReactGA.pageview(page);
  };

  function PageWrapper({ location }) {
    useEffect(() => {
      const page = location.pathname;
      trackPage(page);
      window.scrollTo(0, 0);
    }, location);
    return (
      <>
        <div className={styles.container}><Page /></div>
        <Footer />
      </>
    );
  }

  return PageWrapper;
};

const App = () => {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Route exact path="/tenants" component={withContainer(ForTenants)} />
        <Route
          path="/tenants/:id"
          render={props => (
            <div className={styles.container}>
              <ForTenantsSubpage {...props} />
            </div>
          )}
        />
        <Route
          exact
          path="/"
          render={() => (
            <>
              <Home />
              <Footer />
            </>
          )}
        />
        <Route path="/contact" component={withContainer(Contact)} />
        <Route path="/available-housing" component={AvailableHousing} />
        <Route path="/about-us" component={withContainer(AboutUs)} />
        <Route exact path="/properties" component={withContainer(PropertyListScreen)} />
        <Route exact path="/employees" component={withContainer(Employees)} />
        <Route path="/report-error" component={withContainer(ReportError)} />
        <Route
          path="/properties/:id"
          render={props => (
            <>
              <div className={styles.largeContainer}>
                <PropertyScreen {...props} />
              </div>
              <Footer />
            </>
          )}
        />
      </div>
    </Router>
  );
};

export default App;

import React from 'react';
import Fade from 'react-reveal/Fade';
import useImage from '../../components/shared/Image';
import styling from './styles.module.css';
import styles from '../../components/properties/Property.module.css';

import { useDocument } from '../../utils/firebase_utils';

const AboutUs = () => {
  const content = useDocument('textContent', 'aboutUsContent');
  const contentIsLoaded = Object.keys(content).length > 0;

  const title = contentIsLoaded ? content.om_oss_tekst.title : '';
  const description = contentIsLoaded ? content.om_oss_tekst.description : '';

  const images = useDocument('images', 'frontpageImages');
  const imagesLoaded = Object.keys(images).length > 0;

  const [mainImage] = useImage(
    imagesLoaded ? images.bilde_ansatte_forside.photo : '',
    'Bilde av ansatte',
    styling.img
  );

  return (
    <Fade duration={1000}>
      <div>
        {mainImage}
        <h1> {title} </h1>
        <div
          className={styles.shortDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Fade>
  );
};

export default AboutUs;

import { useEffect, useState } from 'react';
import fire from '../fire';

const useDocument = (collectionName, docName) => {
  const [content, setContent] = useState({});

  const loadContent = async () => {
    let textContent = {};

    const db = fire.firestore();
    await db
      .collection(collectionName)
      .doc(docName)
      .get()
      .then(doc => {
        textContent = doc.data();
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });

    return textContent;
  };

  useEffect(() => {
    loadContent().then(loadedContent => {
      setContent(loadedContent);
    });
  }, {});

  return content;
};

const useCollection = collectionName => {
  const [collection, setCollection] = useState([]);

  const loadCollection = async () => {
    const collectionObjects = [];

    const db = fire.firestore();
    const querySnapshot = await db.collection(collectionName).get();
    querySnapshot.forEach(collectionObject => collectionObjects.push(collectionObject.data()));

    setCollection(collectionObjects);
  };

  useEffect(() => {
    loadCollection();
  }, {});

  return collection;
};

export { useDocument, useCollection };

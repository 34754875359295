import React from 'react';
import Fade from 'react-reveal/Fade';
import styling from './Contact.module.css';
import { useDocument } from '../../utils/firebase_utils';

const ContactPage = () => {
  const content = useDocument('textContent', 'contactContent');
  const contentIsLoaded = Object.keys(content).length > 0;

  const contactTitle = contentIsLoaded ? content.innhold_kontaktside.title : '';
  const contactSubTitle = contentIsLoaded ? content.innhold_kontaktside.subtitle : '';
  const contactEmail = contentIsLoaded ? content.innhold_kontaktside.email : '';
  const contactPhone = contentIsLoaded ? content.innhold_kontaktside.phone : '';

  const contactAddress = contentIsLoaded ? content.adresse_kontaktside.address : '';
  const contactCity = contentIsLoaded ? content.adresse_kontaktside.city : '';
  const contactPostalCode = contentIsLoaded ? content.adresse_kontaktside.postalCode : '';

  return (
    <div>
      <Fade duration={1000}>
        <div className={styling.header}>
          <h1>
            {contactTitle}
            <br />
            {contactSubTitle}
          </h1>
          <div className={styling.contact}>
            <div>
              <div>
                <h2>Epost</h2>
                <a href={`mailto: ${contactEmail}`}>{contactEmail}</a>
              </div>

              <div>
                <h2>Telefon</h2>
                <a href={`tel: ${contactPhone}`}>{contactPhone}</a>
              </div>
            </div>
            <div>
              <div>
                <h2>Adresse</h2>
                <p>{contactAddress}</p>
                <p>
                  {contactPostalCode} {contactCity}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ContactPage;

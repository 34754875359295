import axios from 'axios';
import React, { useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { Fade } from 'react-reveal';
import Select from 'react-select';
import { useCollection } from '../../utils/firebase_utils';
import styling from './ReportError.module.css';

const ReportErrorPage = () => {
  const [name, setName] = useState('');
  const [phoneNr, setPhonenr] = useState('');
  const [email, setEmail] = useState('');
  const [fileName, setfileName] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedAppartment, setSelectedAppartment] = useState(null);
  const [incident, setIncident] = useState(null);
  const [incidentDesc, setIncidentDesc] = useState('');
  const [problemDesc, setProblemDesc] = useState('');
  const [selfEffort, setSelfEffort] = useState('');
  const [selectedIncidentImg, setSelectedIncidentImg] = useState(null);
  const [consent, setConsent] = useState(null);
  const [timeSlots, setTimeSlots] = useState('');
  const [formSubmitted, setformSubmitted] = useState(false);

  // placeholder values
  const properties = useCollection('properties');
  const incidents = useCollection('incidents');

  const propertiesOptions = properties.map(property => ({
    value: property.streetAddress,
    label: property.streetAddress
  }));

  const incidentOptions = [
    ...incidents.map(inci => ({
      value: inci.name,
      label: inci.name
    })),
    {
      value: 'annet',
      label: 'Annet'
    }
  ];

  const calcAppartments = () => {
    const res = {};
    for (let i = 0; i < properties.length; i += 1) {
      const currentProperty = properties[i];
      res[currentProperty.streetAddress] = currentProperty.appartments
        ? currentProperty.appartments
        : [];
    }
    return res;
  };

  const appartments = calcAppartments();

  properties.map(property => {
    const relatedAppartments = property.appartments ? property.appartments : [];
    return { [property.streetAddress]: relatedAppartments };
  });

  let appartmentOptions = [];

  if (selectedProperty && selectedProperty.value) {
    const key = selectedProperty.value;
    const buildingApparments = appartments[key];
    appartmentOptions = buildingApparments.map(appartment => ({
      label: appartment,
      value: appartment
    }));
  }

  appartmentOptions.sort((a, b) => {
    return a.label > b.label ? 1 : -1;
  });

  const resetState = () => {
    setName('');
    setPhonenr('');
    setEmail('');
    setfileName('');
    setSelectedProperty(null);
    setSelectedAppartment(null);
    setProblemDesc('');
    setSelfEffort('');
    setSelectedIncidentImg(null);
    setConsent(null);
    setTimeSlots('');
    setformSubmitted(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const functionUrl = `https://europe-west1-bybroenbolig-web.cloudfunctions.net/sendEmail`;
    try {
      axios.post(functionUrl, {
        name,
        email,
        phoneNr,
        file: selectedIncidentImg,
        address: selectedProperty ? selectedProperty.label : '',
        appartmentNr: selectedAppartment ? selectedAppartment.label : '',
        incident: incident ? incident.label : '',
        incidentDesc,
        selfEffort,
        timeSlots,
        problemDesc,
        consent: consent ? consent.label : ''
      });
      resetState();
    } catch (err) {
      console.log(err);
    }
  };

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        700,
        700,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri);
        },
        'base64'
      );
    });

  const handleImgUpload = async e => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    setfileName(file.name);
    setSelectedIncidentImg(image);
  };

  propertiesOptions.sort((a, b) => {
    return a.label > b.label ? 1 : -1;
  });

  // is true if all required fields have data
  let isActive =
    selectedProperty &&
    name &&
    (appartmentOptions.length <= 0 || (appartmentOptions && selectedAppartment)) &&
    email &&
    phoneNr &&
    incident &&
    consent &&
    problemDesc &&
    selfEffort;

  if (consent) {
    if (!consent.value && !timeSlots) {
      isActive = false;
    }
  }

  if (incident) {
    if (incident.value === 'annet' && !incidentDesc) {
      isActive = false;
    }
  }

  return (
    <div className={styling.content}>
      <Fade duration={1000}>
        <div className={styling.header}>
          <h1>Meld inn feil/mangler</h1>
          <div className={styling.contact}>
            <div className={styling.form}>
              <div className={styling.formRow}>
                <p>Ditt navn:</p>{' '}
                <div>
                  <input
                    className={styling.input}
                    onChange={e => setName(e.target.value)}
                    value={name}
                  />
                </div>
              </div>
              <div className={styling.formRow}>
                <p>Adresse:</p>{' '}
                <div>
                  <Select
                    options={propertiesOptions}
                    placeholder="Velg adresse"
                    onChange={value => {
                      setSelectedProperty(value);
                      setSelectedAppartment(null);
                    }}
                    value={selectedProperty}
                  />
                </div>
              </div>
              {appartmentOptions.length > 0 && (
                <div className={styling.formRow}>
                  <p>Ref. nr:</p>{' '}
                  <div>
                    <Select
                      options={appartmentOptions}
                      placeholder="Velg relatert bolig"
                      onChange={value => setSelectedAppartment(value)}
                      value={selectedAppartment}
                    />
                  </div>
                </div>
              )}
              <div className={styling.formRow}>
                <p>Telefonnr:</p>{' '}
                <div>
                  <input
                    type="tel"
                    className={styling.input}
                    onChange={e => setPhonenr(e.target.value)}
                    value={phoneNr}
                  />
                </div>
              </div>
              <div className={styling.formRow}>
                <p>Epostadr:</p>{' '}
                <div>
                  <input
                    type="email"
                    className={styling.input}
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
              <div className={styling.formRow}>
                <p>Hva har oppstått?</p>{' '}
                <div>
                  <Select
                    options={incidentOptions}
                    placeholder="Velg hendelse"
                    onChange={value => setIncident(value)}
                    value={incident}
                  />
                </div>
                {incident && incident.value === 'annet' && (
                  <div className={styling.formRow}>
                    <p>Spesifiser problemet:</p>{' '}
                    <div>
                      <input
                        className={styling.input}
                        onChange={e => setIncidentDesc(e.target.value)}
                        value={incidentDesc}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={styling.formRow}>
                <p>Beskriv problemet?</p>{' '}
                <div>
                  <textarea
                    className={styling.textfield}
                    value={problemDesc}
                    onChange={e => setProblemDesc(e.target.value)}
                  />
                </div>
              </div>
              <div className={styling.formRow}>
                <p>Hva har du selv forsøkt å gjøre?</p>{' '}
                <div>
                  <textarea
                    className={styling.textfield}
                    value={selfEffort}
                    onChange={e => setSelfEffort(e.target.value)}
                  />
                </div>
              </div>
              <div className={styling.formRow}>
                <p>Legg gjerne ved bilde som beskriver problemet</p>{' '}
                <div style={{ marginTop: '8px' }}>
                  <label htmlFor="file" className={styling.fileBtn}>
                    Last opp bilde
                  </label>
                  <input
                    type="file"
                    id="file"
                    name="incidentImg"
                    className={styling.fileUpload}
                    style={{ visibility: 'hidden' }}
                    accept="image/png, image/jpeg"
                    onChange={handleImgUpload}
                  />
                </div>
                <p>{fileName}</p>
              </div>

              <div className={styling.formRow}>
                <p>
                  Dersom vi må sende personell for å utbedre problemet og du/dere ikke er hjemme,
                  har vi da tillatelse til å låse oss inn?
                </p>{' '}
                <div>
                  <Select
                    options={[{ label: 'Ja', value: true }, { label: 'Nei', value: false }]}
                    placeholder="Velg ja/nei"
                    onChange={value => {
                      if (value.value) {
                        setTimeSlots('');
                      }
                      setConsent(value);
                    }}
                    value={consent}
                  />
                </div>
                {consent && !consent.value && (
                  <div className={styling.formRow}>
                    <p>
                      Foreslå 2 ulike tidspunkt mellom kl. 08:00 og 14:00 man-fre hvor du/dere er
                      tilgjengelig:
                    </p>{' '}
                    <div>
                      <input
                        className={styling.input}
                        onChange={e => setTimeSlots(e.target.value)}
                        value={timeSlots}
                      />
                      <p>
                        <b>Du vil bli kontaktet for nærmere avtale.</b>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className={styling.formRow}>
                <p>Bekreft innsending</p>
                <div>
                  <button
                    className={isActive ? `${styling.submitBtn}` : `${styling.submitBtnDisabled}`}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isActive}
                  >
                    Meld inn
                  </button>
                  {formSubmitted && <p className={styling.submittedText}>Skjema er meldt inn!</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ReportErrorPage;

import React from 'react';
import Fade from 'react-reveal/Fade';
import Card from '../../components/for-tenants/Card';
import styling from './ForTenants.module.css';

import { useDocument } from '../../utils/firebase_utils';

const ForTenants = () => {
  const content = useDocument('textContent', 'forTentantsContent');
  const contentIsLoaded = Object.keys(content).length > 0;

  const title = contentIsLoaded ? content.kontaktinfo_for_leietakere.title : '';
  const description = contentIsLoaded ? content.kontaktinfo_for_leietakere.description : '';

  const lostKeyDescription = contentIsLoaded ? content.glemt_nøkkel.title : '';
  const houseRulesDescription = contentIsLoaded ? content.husordensregler.title : '';
  const internettDescription = contentIsLoaded ? content.internett_kabel_tv.title : '';
  const cleaningDescription = contentIsLoaded ? content.renhold.title : '';

  const images = useDocument('images', 'tenantImages');
  const imagesLoaded = Object.keys(images).length > 0;

  return (
    <div>
      <Fade duration={1000}>
        <div className={styling.header}>
          <h1>For leietakere</h1>
          <div className={styling.contact}>
            <div>
              <div>
                <h2>{title}</h2>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <div className={styling.grid}>
        <Card
          image={{
            url: imagesLoaded ? images.bilde_glemt_nøkkel.photo : '',
            alt: 'Mistet nøkkel',
            styling: styling.img
          }}
          imgName="mistet-nokkel"
          description={lostKeyDescription}
        />
        <Card
          image={{
            url: imagesLoaded ? images.bilde_husordensregler.photo : '',
            alt: 'Husordensregler',
            styling: styling.img
          }}
          imgName="husordensregler"
          description={houseRulesDescription}
        />
        <Card
          image={{
            url: imagesLoaded ? images.bilde_internett_kabeltv.photo : '',
            alt: 'Forsidebilde',
            styling: styling.img
          }}
          imgName="internett"
          description={internettDescription}
        />
        <Card
          image={{
            url: imagesLoaded ? images.bilde_renhold.photo : '',
            alt: 'Renhold',
            styling: styling.img
          }}
          imgName="renhold"
          description={cleaningDescription}
        />
        <Card
          image={{
            url: imagesLoaded ? images.bilde_avfall.photo : '',
            alt: 'Avfall',
            styling: styling.img
          }}
          imgName="avfall"
          description="Avfall"
        />
        <div className={styling.emptyCard} />
      </div>
    </div>
  );
};

export default ForTenants;

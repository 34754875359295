import React, { useState } from 'react';
import 'firebase/storage';

const useImage = (url, alt, name) => {
  const [loaded, setLoaded] = useState(false);

  return [
    <img alt={alt} className={name} src={url} onLoad={() => setLoaded(url !== '')} />,
    loaded
  ];
};

export default useImage;

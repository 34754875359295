import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import { useDocument } from '../../utils/firebase_utils';
import CustomMessage from './CustomMessage';
import styling from './NavBar.module.css';

const NavBar = () => {
  const [menuOpen, openMenu] = useState(false);

  const content = useDocument('textContent', 'customMessage');
  const contentIsLoaded = Object.keys(content).length > 0;

  const customMessage = contentIsLoaded ? content.value : '';

  const toggleMenu = state => {
    openMenu(state);
    document.body.classList.toggle('mobileMenuMode', state);
  };

  const menuContent = (
    <div className={styling.subPages}>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/">
          Hjem
        </NavLink>
      </div>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/available-housing">
          Ledige boliger
        </NavLink>
      </div>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/properties">
          Våre eiendommer
        </NavLink>
      </div>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/tenants">
          For leietakere
        </NavLink>
      </div>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/employees">
          Våre ansatte
        </NavLink>
      </div>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/about-us">
          Om oss
        </NavLink>
      </div>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/report-error">
          Meld inn feil/mangler
        </NavLink>
      </div>
      <div className={styling.link}>
        <NavLink onClick={() => toggleMenu(false)} to="/contact">
          Kontakt oss
        </NavLink>
      </div>
    </div>
  );

  return (
    <div>
      <Toolbar
        id="navbar"
        className={menuOpen ? `${styling.toolbar} ${styling.mobileMenu}` : `${styling.toolbar}`}
      >
        <div className={styling.content}>
          <div>
            <NavLink to="/">
              <img className={styling.logo} src={Logo} alt="Logo" />
            </NavLink>
          </div>
          {menuOpen && (
            <Fade bottom duration={800} distance="20px">
              {menuContent}
            </Fade>
          )}
          {!menuOpen && menuContent}
          <div className={styling.menuIcon}>
            <Button onClick={() => toggleMenu(true)}>
              <Icon fontSize="large">menu</Icon>
            </Button>
          </div>
          <div className={styling.closeIcon}>
            <Button onClick={() => toggleMenu(false)}>
              <Icon fontSize="large">close</Icon>
            </Button>
          </div>
        </div>
      </Toolbar>
      <CustomMessage message={customMessage} />
    </div>
  );
};

export default NavBar;

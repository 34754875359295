import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// eslint-disable-next-line import/prefer-default-export
export const initSentry = () => {
  console.log('Initializing Sentry...');
  Sentry.init({
    dsn: 'https://548d7052e8624342a671f33a32cfe807@o1301090.ingest.sentry.io/6536634',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2
  });
};

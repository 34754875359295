import React, { useState, useEffect } from 'react';

import fire from '../../fire';
import PropertyItem from './PropertyItem';
import styles from './Property.module.css';
import PropertyFilter from './PropertyFilter';

const useProperties = () => {
  const [properties, setProperties] = useState([]);
  const [propertiesToDisplay, setPropertiesToDisplay] = useState([]);

  // Fetches property objects from firestore and sets the properties state
  const loadProperties = async () => {
    // Initialize empty list
    const propertyObjects = [];

    // Get properties from firestore
    const db = fire.firestore();
    const querySnapshot = await db.collection('properties').get();
    // Fill propertyObjects with the property objects
    querySnapshot.forEach(p => propertyObjects.push(p.data()));

    // Set the state with the objects from firestore
    setProperties(propertyObjects);
    setPropertiesToDisplay(propertyObjects);
  };

  const displayPropertiesInArea = area => {
    if (area === 'Alle') {
      setPropertiesToDisplay(properties);
    } else {
      const propertiesInArea = properties.filter(p => p.area === area);
      setPropertiesToDisplay(propertiesInArea);
    }
  };

  // Load all properties on initial load
  useEffect(() => {
    loadProperties();
  }, []);

  return [propertiesToDisplay, displayPropertiesInArea];
};

const PropertyGrid = () => {
  const [properties, changeArea] = useProperties();

  const propertyJSX = properties.map(p => <PropertyItem key={p.streetAddress} propertyData={p} />);

  return (
    <>
      <PropertyFilter updateArea={changeArea} />
      <div className={styles.grid}>
        {properties.length === 0 ? <h1>Ingen resultater</h1> : propertyJSX}
      </div>
    </>
  );
};

export default PropertyGrid;

import React, { useEffect, useState } from 'react';
import styles from './AvailableHousingStyle.module.css';

const AvailableHousing = () => {
  const [height, setHeight] = useState('700px');
  useEffect(() => {
    const navbar = document.getElementById('navbar');
    setHeight(window.innerHeight - navbar.offsetHeight - 10);
  });
  return (
    <div className={styles.finnWrapper}>
      <iframe
        id="iframe"
        className={styles.finnIframe}
        frameBorder="0"
        scroll="auto"
        height={height}
        width="100%"
        title="finn-annonser"
        src="https://www.finn.no/pw/search/realestate-letting?orgId=654887043"
      />
    </div>
  );
};

export default AvailableHousing;

import React from 'react';
import styling from './CustomMessage.module.css';

const CustomMessage = props => {
  const { message } = props;
  if (message === '' || message === 0) {
    return <div />;
  }

  return (
    <div className={styling.customMessage}>
      <h3>{message}</h3>
    </div>
  );
};

export default CustomMessage;

import React from 'react';
import EmployeeCard from '../../components/contact/EmployeeCard';
import styling from '../contact/Contact.module.css';
import { useCollection } from '../../utils/firebase_utils';

const EmployeesPage = () => {
  // Sort employees by a custom number which the user can define in the admin-panel
  const employees = useCollection('employees').sort(
    (a, b) => parseInt(a.sortNumber) - parseInt(b.sortNumber)
  );

  const employeeJSX = employees.map(employee => (
    <EmployeeCard key={employee.name} employee={employee} />
  ));

  return <div className={styling.grid}>{employeeJSX}</div>;
};

export default EmployeesPage;

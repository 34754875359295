import React from 'react';

import PropertyGrid from '../../components/properties/PropertyGrid';
import styles from './PropertyStyles.module.css';

const PropertyListScreen = () => {
  return (
    <div>
      <h1 className={styles.header}>
        {' '}
        Vi er stolte av våre
        <br />
        eiendommer.{' '}
      </h1>
      <PropertyGrid />
    </div>
  );
};

export default PropertyListScreen;

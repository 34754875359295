import React from 'react';
import styling from './Footer.module.css';

const Footer = () => (
  <footer className={styling.footer}>
    <h1>Kontakt oss</h1>
    <div className={styling.contact}>
      <div>
        <p className={styling.subtitle}>Adresse</p>
        <p>Munkegt. 39/41</p>
        <p>7011 TRONDHEIM</p>
      </div>
      <div>
        <p className={styling.subtitle}>Åpningstider</p>
        <p>Mandag - Fredag</p>
        <p>Kl 09.00 - 15.00</p>
      </div>
      <div>
        <p className={styling.subtitle}>Kontaktinfo</p>
        <a href="tel:+4773561100" className={styling.underline}>
          (+47) 73 56 11 00
        </a>
        <p>post@bybroenbolig.no</p>
      </div>
    </div>
  </footer>
);

export default Footer;

import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyAZuC7rxUPkvNCoB2exOxWGFpn_cL75FvM',
  authDomain: 'bybroenbolig-web.firebaseapp.com',
  databaseURL: 'https://bybroenbolig-web.firebaseio.com',
  projectId: 'bybroenbolig-web',
  storageBucket: 'bybroenbolig-web.appspot.com',
  messagingSenderId: '337742402686'
};
const fire = firebase.initializeApp(config);
export default fire;

import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Fade from 'react-reveal/Fade';
import fire from '../../fire';
import { SmallMap } from '../../components/shared/Maps';
import styles from './PropertyStyles.module.css';

const useProperty = streetAddress => {
  const [propertyData, setPropertyData] = useState({
    photos: [],
    description: '',
    streetAddress: '',
    postalCode: '',
    city: ''
  });

  const loadProperty = async address => {
    const db = fire.firestore();

    // Create a reference to the properties collection
    const propertiesRef = db.collection('properties');

    // Create a query against the collection.
    const query = propertiesRef.where('streetAddress', '==', address);
    const querySnapshot = await query.get();
    const data = querySnapshot.docs[0].data();
    setPropertyData(data);
  };

  // Load in the property with the adress provided in the url
  useEffect(() => {
    loadProperty(streetAddress);
  }, []);

  return [propertyData];
};

const PropertyScreen = ({ match }) => {
  const [propertyData] = useProperty(match.params.id);

  const imageCarousel = (
    <Carousel useKeyboardArrows>
      {propertyData.photos.map(photo => (
        <div className={styles.carouselItem} key={photo.photo}>
          <img alt="bilde av eiendom" src={photo.photo} />
        </div>
      ))}
    </Carousel>
  );

  const displayAnnet =
    propertyData.garden || propertyData.parking ? (
      <>
        <h2>Annet</h2>
        {propertyData.garden ? <p>Hage</p> : ''}
        {propertyData.parking ? <p>Parkering</p> : ''}
      </>
    ) : (
      ''
    );

  const showMap = propertyData.lat && propertyData.lng;
  return (
    <Fade bottom>
      <div className={styles.propertyContainer}>
        {propertyData.photos.length ? imageCarousel : ''}
        <div className={styles.address}>
          <h1>{propertyData.streetAddress}</h1>
          <h2>{`${propertyData.postalCode}, ${propertyData.city}`}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: propertyData.description }} />
        {displayAnnet}
        {showMap ? <SmallMap lat={propertyData.lat} lng={propertyData.lng} /> : ''}
      </div>
    </Fade>
  );
};

export default PropertyScreen;

import React from 'react';
import Fade from 'react-reveal/Fade';
import styling from './EmployeeCard.module.css';
import useImage from '../shared/Image';

const EmployeeCard = employee => {
  const [renderedImaged, loaded] = useImage(
    employee.employee.photo,
    `Bilde av ${employee.employee.name}`
  );

  return (
    <div>
      {loaded && (
        <Fade bottom distance="40px" duration={900}>
          <div className={styling.card}>
            {renderedImaged}
            <h2>{employee.employee.name}</h2>
            <h3>{employee.employee.title}</h3>
            <a href={`mailto: ${employee.employee.email}`}>{employee.employee.email}</a>
            <a href={`tel:${employee.employee.phone}`}>
              Telefon direkte: {employee.employee.phone}
            </a>
            {employee.employee.phoneSwitchboard && (
              <a href={`tel:${employee.employee.phoneSwitchboard}`}>
                Telefon sentralbord: {employee.employee.phoneSwitchboard}
              </a>
            )}
          </div>
        </Fade>
      )}
      {!loaded && <div style={{ visibility: 'hidden' }}>{renderedImaged}</div>}
    </div>
  );
};

export default EmployeeCard;

import React from 'react';
import Fade from 'react-reveal/Fade';
import styling from './style.module.css';

const Section = ({ children, loaded, header }) => (
  <div>
    {loaded && (
      <Fade bottom={!header} distance="90px" duration={header ? 300 : 1000}>
        <div className={styling.section}>{children}</div>
      </Fade>
    )}
    {!loaded && (
      <div style={{ visibility: 'hidden' }} className={styling.section}>
        {children}
      </div>
    )}
  </div>
);

export default Section;

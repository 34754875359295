import React from 'react';
import { ArrowRightAlt } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import styles from './Property.module.css';

const PropertyItem = ({ propertyData, match }) => {
  const { description, streetAddress, photos } = propertyData;

  const previewDescription =
    description.length > 90 ? `${description.substring(0, 90)}...` : description;

  return (
    <Link to={`${match.path}/${streetAddress}`} className={styles.link}>
      <Fade bottom distance="40px" duration={900}>
        <div className={styles.property}>
          <div className={styles.imageWrapper}>
            <img src={photos[0].photo} alt={`bilde av ${streetAddress}`} />
          </div>
          <h3> {streetAddress} </h3>
          <div
            className={styles.shortDescription}
            dangerouslySetInnerHTML={{ __html: previewDescription }}
          />
          <ArrowRightAlt className={styles.arrowIcon} />
        </div>
      </Fade>
    </Link>
  );
};

const PropertyWithRouter = withRouter(PropertyItem);
export default PropertyWithRouter;
